// @ts-nocheck

// REACT
import React, { Fragment, useCallback, useEffect, useReducer, useState } from 'react';

//STYLE
import { Pagination, Radio, Table } from 'antd';
import { JellyContainer, JellyContainerTop } from '../../style';
import { NoDataImg, NoDataTxt, NoDataWrap, ReviewRowContent, ReviewSortWrap, ReviewTableWrap, ReviewTitle } from './style';

//API
import { getReviewList, replyReview, requestBlind } from '../../api';

//UTIL
import jwt from 'jwt-decode';
import { BLIND_STATUS, getToken } from '../../services';

//COMPONENT
import { JellyButton } from '../../components';
import DialogBlind from './DialogBlind';
import DialogReviewReply from './DialogReviewReply';

//TYPE
import { IReviewItem } from '../../api/review';

//IMAGE
import NodataCustomer from '../../assets/images/ic-cus-list-none.svg';
import useAuthStore from '../../store/auth/authStore';
//API
interface IJWT {
	id: string;
	branchName: string;
	name: string;
	exp: number;
	branchUid: string;
	viewBranchUid: string;
}
interface IReply {
	visible: boolean;
	data: IReviewItem | null;
}
interface IBlind {
	visible: boolean;
	rvId: number;
}

export interface IReviewListParams {
	branchId: '' | undefined;
	isReply: '' | 0 | 1;
	page: number;
	pageView: number;
}

type TParamsAction = { type: 'SET_BRANCH_ID'; branchId: string } | { type: 'SET_IS_REPLY'; isReply: '' | 0 | 1 } | { type: 'SET_PAGE'; page: number };

const paramsReducer = (state: IReviewListParams, action: TParamsAction) => {
	switch (action.type) {
		case 'SET_BRANCH_ID':
			return {
				...state,
				branchId: action.branchId,
			};
		case 'SET_IS_REPLY':
			return {
				...state,
				isReply: action.isReply,
			};
		case 'SET_PAGE':
			return {
				...state,
				page: action.page,
			};
	}
};

const Review: React.FC = () => {
	const { isMaster, pccIndex, branchList = [] } = useAuthStore();
	const [userId, setUserId] = useState<string>('');
	const [reviewList, setReviewList] = useState<IReviewItem[]>([]);
	const [params, setParams] = useReducer(paramsReducer, {
		branchId: '',
		isReply: '',
		page: 1,
		pageView: 10,
	});
	const [totalCount, setTotalCount] = useState(0);
	const [reply, setReply] = useState<IReply>({
		visible: false,
		data: null,
	});
	const [blind, setBlind] = useState<IBlind>({
		visible: false,
		rvId: -1,
	});
	const columns = [
		{
			title: ' ',
			name: 'no',
			dataIndex: 'no',
			key: 'no',
		},
		{
			title: '작성일자',
			name: 'regDt',
			dataIndex: 'regDt',
			key: 'regDt',
		},
		{
			title: '평점',
			name: 'score',
			dataIndex: 'score',
			key: 'score',
		},
		{
			title: '리뷰내용',
			name: 'text',
			dataIndex: 'text',
			key: 'text',
			width: '500px',
			render(text: string, row: IReviewItem) {
				return (
					<ReviewRowContent
						onClick={() =>
							setReply({
								visible: true,
								data: row,
							})
						}
					>
						{text}
					</ReviewRowContent>
				);
			},
		},
		{
			title: '답변',
			name: 'replyContents',
			dataIndex: 'replyContents',
			key: 'replyContents',
			width: '500px',
		},
		{
			title: '블라인드 상태',
			name: 'isBlind',
			dataIndex: 'isBlind',
			key: 'isBlind',
			width: '200px',
			render(e: number, row: IReviewItem) {
				if (e === BLIND_STATUS.NOT_BLIND) {
					return (
						<JellyButton
							bgType="white"
							onClick={() => {
								setBlind({
									visible: true,
									rvId: row.rvId,
								});
							}}
						>
							블라인드 처리
						</JellyButton>
					);
				} else {
					return (
						<JellyButton disabled bgType="jellybook">
							블라인드 처리 완료
						</JellyButton>
					);
				}
			},
		},
	];

	// useEffect(() => console.log('branchList', branchList), [branchList]);
	// useEffect(() => {
	// 	console.log('branchList', branchList[pccIndex]);
	// 	if (!isMaster) {
	// 		setParams({
	// 			...params,
	// 			branchId: branchList[pccIndex]?.branchId,
	// 		});
	// 	}
	// }, [pccIndex, branchList]);

	const getData = useCallback(async () => {
		console.log(isMaster, pccIndex, branchList);
		try {
			if (isMaster && pccIndex !== 0 && branchList.length > 0) {
				// 마스터 검색
				console.log('master');
				const { totalCnt, period } = await getReviewList({
					...params,
				});
				setTotalCount(totalCnt);
				setReviewList(period);
			} else if (isMaster === false && params.branchId !== '') {
				// 일반 검색
				console.log('basic');
				const { totalCnt, period } = await getReviewList({
					...params,
				});
				setTotalCount(totalCnt);
				setReviewList(period);
			} else {
				setTotalCount(0);
				setReviewList([]);
			}
		} catch (e) {
			console.log(e);
		}
	}, [params, pccIndex]);

	const handlePressReplyClose = () => {
		setReply({
			visible: false,
			data: null,
		});
	};

	const handlePressBlindClose = () => {
		setBlind({
			visible: false,
			rvId: -1,
		});
	};

	const handleChangeState = (isReply: '' | 0 | 1) => {
		setParams({
			type: 'SET_IS_REPLY',
			isReply,
		});
	};

	const handleReply = async (rvId: number, reply: string) => {
		try {
			await replyReview({
				rvId,
				reply,
				replyRegId: userId,
			});

			getData();
			setReply({
				visible: false,
				data: null,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const handleBlind = async (rvId: number, blindReason: string) => {
		try {
			await requestBlind({
				rvId,
				blindReason,
				blindRegId: userId,
			});
			getData();
			setBlind({
				visible: false,
				rvId: -1,
			});
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		const token = JSON.stringify(getToken());
		const { id, viewBranchUid }: IJWT = jwt(token);
		setUserId(id);
		setParams({ type: 'SET_BRANCH_ID', branchId: viewBranchUid });
	}, []);

	useEffect(() => {
		getData();
	}, [getData, pccIndex]);
	return (
		<Fragment>
			<JellyContainer>
				<JellyContainerTop justifyContent="space-between">
					<ReviewTitle>리뷰관리</ReviewTitle>
					<ReviewSortWrap>
						<Radio.Group value={params.isReply} onChange={(e) => handleChangeState(e.target.value)}>
							<Radio value="">전체보기</Radio>
							<Radio value={0}>미답변</Radio>
							<Radio value={1}>답변완료</Radio>
						</Radio.Group>
					</ReviewSortWrap>
				</JellyContainerTop>
				<ReviewTableWrap>
					<Table
						columns={columns}
						dataSource={reviewList}
						pagination={false}
						rowKey="rvId"
						locale={{
							emptyText: (
								<NoDataWrap>
									<NoDataImg src={NodataCustomer} alt="No data" title="No data" />
									<NoDataTxt>등록된 리뷰가 없습니다.</NoDataTxt>
								</NoDataWrap>
							),
						}}
					/>
					<Pagination
						defaultCurrent={1}
						current={params.page}
						className="jellybook"
						total={totalCount}
						defaultPageSize={params.pageView}
						onChange={(idx: number) => {
							setParams({
								type: 'SET_PAGE',
								page: idx,
							});
						}}
					/>
				</ReviewTableWrap>
			</JellyContainer>
			<DialogBlind visible={blind.visible} rvId={blind.rvId} onPressClose={handlePressBlindClose} onBlind={handleBlind} />
			<DialogReviewReply visible={reply.visible} data={reply.data} onPressClose={handlePressReplyClose} onReply={handleReply} />
		</Fragment>
	);
};
export default Review;
