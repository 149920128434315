// @ts-nocheck

// //UTIL
// import { $http } from "../utils";

// //TYPE
// import { IApiResponse, TSuccess } from "../utils/type";

//SERVICES

import Axios from 'axios';
import { VIEW_MASTER_API_BASE_URL } from '../../config';
import { $http, IApiResponse, TSuccess } from '../../services';
import { getApiToken } from '../../utils';

export interface ICustomerInfo {
	name: string;
	phone: string;
	birth: string;
	baby1: string;
	baby2: string | null;
	baby3: string | null;
	baby4: string | null;
	index?: string | null;
	key?: string | null;
	customerUid?: string | null;
	checkin?: 'Y' | 'N' | string | null;
	[propName: string]: any;
}

interface IGetCustomerListResponse extends IApiResponse {
	totalCnt: number;
	period: ICustomerInfo[];
}
interface IDownloadCustomerListResponse extends IApiResponse {
	period: IDownloadPeriod;
}

interface IGetCustomerDetailResponse extends IApiResponse {
	period: ICustomerInfo;
}

interface IGetCustomerListRequest {
	checkin?: 'Y' | 'N' | 'ALL' | null;
	page?: number;
	searchVal?: string;
}

interface IDownloadCustomerListRequest {
	checkin?: 'Y' | 'N' | 'ALL' | null;
	searchVal?: string;
	excelPass: string | number;
}

interface ICustomerUid {
	customerUid?: string | null;
}

export interface IDownloadPeriod {
	excelLink: string;
	excelName: string;
}
interface IAddCustomerInfoResponse extends IApiResponse {
	no: number;
	success: TSuccess;
}
interface IDeleteCustomerResponse extends IApiResponse {
	success: TSuccess;
	updateCnt: number;
	updateResultCnt: number;
}

export const getCustomerList = (request: IGetCustomerListRequest) => {
	return new Promise((resolve: (response: IGetCustomerListResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/customerList',
			method: 'GET',
			params: { ...request },
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const addCustomerInfo = (request: ICustomerInfo) => {
	return new Promise((resolve: (response: IAddCustomerInfoResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/customerRegister',
			method: 'POST',
			data: request,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const getCustomerInfoDetail = (request: ICustomerUid) => {
	return new Promise((resolve: (response: IGetCustomerDetailResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/customerInfo',
			method: 'GET',
			params: request,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const modifyCustomerInfoDetail = (request: ICustomerInfo) => {
	return new Promise((resolve: (response: IAddCustomerInfoResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/customerUpdate',
			method: 'PUT',
			data: request,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const deleteCustomer = (request: ICustomerUid) => {
	return new Promise((resolve: (response: IDeleteCustomerResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/customerDelete',
			method: 'DELETE',
			data: request,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const downloadCustomerList = (request: IDownloadCustomerListRequest) => {
	return new Promise((resolve: (response: IDownloadCustomerListResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/CustomerExcelList',
			method: 'GET',
			params: request,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

// ajy 25.01.06
// api 구조 변경
export const getBannerData = () => {
	return new Promise((resolve, reject) => {
		Axios({
			headers: {
				// 'book-token': '37fcf686-b9eb-4125-bd2f-cf83a643722b',
				'authoried-token': getApiToken(),
			},
			baseURL: VIEW_MASTER_API_BASE_URL,
			// url: '/banner',
			url: '/api/banner',
			method: 'GET',
		})
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};
